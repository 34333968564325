import React, { PureComponent, RefObject, MouseEvent } from 'react'
import styled from 'styled-components'
import { WindowLocation } from '@reach/router'
import Slider from 'react-slick'

import Layout from '../components/layout'
import Modal from '../components/modal'
import Section, {SubSection, Header, SubHeader, Divider } from '../components/section/section'
import TextualCarousel, { DisplayItem } from '../components/textualCarousel'

import OverallIcon from '../images/icons/floor-plan.svg'
import LiveRoomIcon from '../images/icons/amplifier.svg'
import OverallSpaceFloorPlan from '../images/space/floor-plans/overall.png'

import HollywoodRoomImage from '../images/gallery/hollywood-room/hollywood-room-2.jpg'
import HollywoodRoomImage2 from '../images/gallery/hollywood-room/hollywood-room-3.jpg'
import HollywoodRoomImage3 from '../images/gallery/hollywood-room/hollywood-room-1.jpg'

import LiveRoomImage1 from '../images/gallery/live-room/live-room-5.jpg'
import LiveRoomImage2 from '../images/gallery/live-room/live-room-4.jpg'
import LiveRoomImage3 from '../images/gallery/live-room/live-room-1.jpg'

import HollywoodRoom360Image from '../images/hollywood-room-360.jpg'
import LiveRoom360Image from '../images/live-room-360.jpg'
import theme from '../theme'

const FloorPlans = styled.img<{maxWidth?: number, maxHeight?: number}>`
  object-fit: contain;
  max-width: ${props => props.maxWidth ? `calc(${props.maxWidth}vw * 9/16)` : 'calc(65vw * 9/16)'};
  max-height: ${props => props.maxHeight ? `calc(${props.maxHeight}vw * 9/16)` : 'calc(65vw * 9/16)'};
  background: rgba(255,255,255,0.1);
  border-radius: 2px;
  padding: 20px;
  margin: 0 auto;
  display: block;
  box-shadow: 0px 2px 10px -5px black;
  flex-shrink: 0;

  :hover {
    cursor: pointer;
    background: rgba(255,255,255,0.15);
  }

  @media(min-width: 1260px) {
    max-width: unset;
  }

  @media(max-width: 1260px) {
    max-width: 100vw;
  }

  @media(max-width: 768px) {
    width: 100%;
    max-height: calc(100vw * 9/16);
    margin-top: 20px;
  }
`

const ImageGrid = styled.div`
  display: grid;
  grid-template-areas: "a1 a2" "b b";
  grid-gap: 1rem;

  @media(max-width: 760px) {
    grid-template-areas: 'a1' 'a2' 'a3';
  }
`

const RoomImage = styled.img`
  width: 100%;
  max-height: calc(100vw * 9/16);
  margin: 0;
  border-radius: 2px;
  object-fit: cover;
  box-shadow: 0px 0px 10px -2px black;

  :hover {
    cursor: pointer;
  }
`

const RoomImageOne = styled(RoomImage)`
  height: 300px;

  @media(max-width: 760px) {
    height: auto;
    grid-area: a1
  }
`

const RoomImageTwo = styled(RoomImage)`
  height: 300px;

  @media(max-width: 760px) {
    height: auto;
    grid-area: a2
  }
`

const RoomImageThree = styled(RoomImage)`
  grid-area: b;

  @media(max-width: 760px) {
    grid-area: a3
  }
`

const SliderWrapper = styled(Slider)`
  .slick-list {
    .slick-track {
      .slick-slide {
        display: flex;
        justify-content: center;
      }
    }
  }
`

const layoutFeatures = [
  {
    icon: OverallIcon,
    title: 'Overall',
    isGoogleIcon: false
  },
  {
    icon: 'star',
    title: 'The Hollywood Room',
  },
  {
    icon: LiveRoomIcon,
    title: 'The Live Room',
    isGoogleIcon: false
  }
]

const hollywoodRoomFeatures = [
  {
    icon: 'store',
    title: 'Pop-up Shops',
    height: '100px'
  },
  {
    icon: 'camera_enhance',
    title: 'Photography Shoots',
    height: '100px'
  },
  {
    icon: 'flare',
    title: 'Red Carpet Events',
    height: '100px'
  },
  {
    icon: 'forum',
    title: 'Press Releases',
    height: '100px'
  },
  {
    icon: 'business_center',
    title: 'Corporate Events',
    height: '100px'
  },
  {
    icon: 'loyalty',
    title: 'Fundraisers & Benefit Parties',
    height: '100px'
  },
  {
    icon: 'grade',
    title: 'Product Launches',
    height: '100px'
  },
  {
    icon: 'weekend',
    title: 'Holiday Parties',
    height: '100px'
  },
  {
    icon: 'cake',
    title: 'Birthday Parties',
    height: '100px'
  },
  {
    icon: 'meeting_room',
    title: 'Board Meeting & Conferences',
    height: '100px'
  }
]

const liveRoomFeatures = [
  {
    icon: 'theaters',
    title: 'Movie Screenings',
    height: '100px'
  },
  {
    icon: 'album',
    title: 'Record Launches',
    height: '100px'
  },
  {
    icon: 'hearing',
    title: 'Listening Parties',
    height: '100px'
  },
  {
    icon: 'equalizer',
    title: 'Concerts & Live Shows',
    height: '100px'
  },
  {
    icon: 'music_note',
    title: 'Music Showcases',
    height: '100px'
  },
  {
    icon: 'whatshot',
    title: 'Fashion Shows',
    height: '100px'
  }
]

type Props = {
  location: WindowLocation
}

type State = {
  toggledImage: string | null
}

export default class Space extends PureComponent<Props, State> {
  private hollywoodRoom360View: RefObject<HTMLDivElement>
  private liveRoom360View: RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)
    this.loadExternalFile = this.loadExternalFile.bind(this)
    this.hollywoodRoom360View = React.createRef()
    this.liveRoom360View = React.createRef()

    this.state = {
      toggledImage: null
    }
  }

  async componentDidMount() {
    try { 
      await this.loadExternalFile('https://cdn.jsdelivr.net/npm/pannellum@2.5.3/build/pannellum.css', 'css')

      try {
        await this.loadExternalFile('https://cdn.jsdelivr.net/npm/pannellum@2.5.3/build/pannellum.js', 'js')
  
        window.pannellum.viewer(this.hollywoodRoom360View.current, {
          type: 'equirectangular',
          panorama: HollywoodRoom360Image,
          autoLoad: true,
          autoRotate: 5
        })

        window.pannellum.viewer(this.liveRoom360View.current, {
          type: 'equirectangular',
          panorama: LiveRoom360Image,
          autoLoad: true,
          autoRotate: 5
        })
      }
      catch(err) {
        console.log('FAILED TO LOAD EXTERNAL SCRIPT: ', err)
      }
    }
    catch (err) {
      console.log('FAILED TO LOAD EXTERNAL CSS: ', err)
    }
  }

  toggleImage = (event: MouseEvent) => {
    this.setState({
      toggledImage: event.currentTarget ? event.currentTarget.src : null
    })
  }

  loadExternalFile = async (url = '', type = '') => {
    return new Promise((resolve, reject) => {
      if (typeof document !== 'undefined') {
        let file

        if (type === 'js') {
          file = document.createElement('script')
          file.type = 'text/javascript'
          file.src = url
        } else {
          file = document.createElement('link')
          file.type = 'text/css'
          file.rel = 'stylesheet'
          file.href = url
        }
        
        file.addEventListener('load', (e) => {
          if (typeof window !== 'undefined') {
            resolve(e)
          } else {
            reject({
              message: e
            })
          }
        })
  
        file.addEventListener('error', (e) => {
          reject({
            ...e
          })
        })
  
        document.body.appendChild(file)
      } else {
        reject({
          message: 'Failed to load external script from '+url
        })
      }
    })
  }

  render() {
    const modalizedImage = this.state.toggledImage ? (
      <Modal handleClose={this.toggleImage}>
        <img 
          src={this.state.toggledImage} 
          alt="Toggled"
          style={{
            maxWidth: '99vw', 
            maxHeight: '90vh',
            padding: '40px',
          }}/>
      </Modal>
    ) : null

    const sliderSettings = {
      dots: false,
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      arrows: false,
      slidesToShow: this.state.isMobile ? 1 : 3,
      slidesToScroll: 1
    }

    return(
      <Layout location={this.props.location}>
        <Section width="100%" margin="0" padding="40px 0" background="whitesmoke" id="space-top">
          <SubSection>
            <Header title="The Layout"/>
            <SubHeader>
              The Venue of Hollywood is a versatile space
              that can be configured in multiple ways to
              suit your particular event. With impressive
              details and amazing views of Hollywood
              Boulevard, The Venue of Hollywood has
              been the site of album release parties, art
              exhibitions, video premieres, musical
              performances, pop-up shops and signings,
              as well as exclusive Hollywood red carpet
              events. It’s the perfect location to make your
              event memorable.
            </SubHeader>
            <Divider themeColor={theme.palette.primary.main}/>
            <Section alignItems="center" justifyContent="center">
              <SliderWrapper {...sliderSettings}>
                {
                  layoutFeatures.map(item => (
                    <DisplayItem 
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                      isGoogleIcon={item.isGoogleIcon} />
                  ))
                }
              </SliderWrapper>
            </Section>
          </SubSection>
        </Section>
        <Section width="100%" margin="0" padding="80px 0" themeColor={theme.palette.primary.main}>
          <SubSection>
            <FloorPlans src={OverallSpaceFloorPlan} onClick={this.toggleImage}/>
          </SubSection>
        </Section>
        <Section width="100%" margin="0" padding="40px 0" background="white">
          <SubSection>
            <Header title="The Hollywood Room"/>
            <SubHeader>
              With a multitude of windows overlooking
              Hollywood Boulevard, as well as the
              Hollywood & Highland complex, along
              with a trio of balconies, the Hollywood
              Room provides sweeping views of the
              Hollywood cityscape and a unique
              location for your event.
            </SubHeader>
            <Divider themeColor={theme.palette.primary.main}/>
            <Section alignItems="center" flexDirection="column">
              <div ref={this.hollywoodRoom360View} style={{ width: '90%', height: '500px', borderRadius: '3px', boxShadow: '0px 2px 10px -2px black' }} />
              <Section width="90%" margin="20px auto" justifyContent="center">
                <ImageGrid>
                  <RoomImageOne 
                    src={HollywoodRoomImage} 
                    onClick={this.toggleImage}
                    />
                  <RoomImageTwo
                    src={HollywoodRoomImage2} 
                    onClick={this.toggleImage}
                    />
                  <RoomImageThree
                    src={HollywoodRoomImage3} 
                    onClick={this.toggleImage}
                    />
                </ImageGrid>
              </Section>
              <div style={{ display: 'block', width: '100%', margin: '40px 0 0 0' }}>
                <SliderWrapper {...sliderSettings}>
                  {
                    hollywoodRoomFeatures.map(item => (
                      <DisplayItem 
                        key={item.title}
                        title={item.title}
                        icon={item.icon} />
                    ))
                  }
                </SliderWrapper>
              </div>
            </Section>
          </SubSection>
        </Section>
        <Section width="100%" margin="0" padding="40px 0" background="whitesmoke">
          <SubSection>
            <Header title="The Live Room"/>
            <SubHeader>
              Like walking into an upscale intimate
              venue, the Live Room provides the
              perfect location for anything from a
              spoken word performance or video
              premiere to a full on performance from
              your favorite band. With lighting and
              sound available, it’s the perfect place
              to make your event a memorable one.
            </SubHeader>
            <Divider themeColor={theme.palette.primary.main}/>
            <Section alignItems="center" background="whitesmoke" flexDirection="column">
              <div ref={this.liveRoom360View} style={{ width: '90%', height: '500px', borderRadius: '3px', boxShadow: '0px 2px 10px -2px black' }} />
              <Section width="90%" margin="20px auto" justifyContent="center">
                <ImageGrid>
                  <RoomImageOne
                    src={LiveRoomImage1} 
                    onClick={this.toggleImage}
                    />
                  <RoomImageTwo 
                    src={LiveRoomImage2} 
                    onClick={this.toggleImage}
                    />
                  <RoomImageThree
                    src={LiveRoomImage3} 
                    onClick={this.toggleImage}
                    />
                </ImageGrid>
              </Section>
              <div style={{ display: 'block', width: '100%', margin: '40px 0 0 0' }}>
                <SliderWrapper {...sliderSettings}>
                  {
                    liveRoomFeatures.map(item => (
                      <DisplayItem 
                        key={item.title}
                        title={item.title}
                        icon={item.icon} />
                    ))
                  }
                </SliderWrapper>
              </div>
            </Section>
          </SubSection>
        </Section>
        {modalizedImage}
      </Layout>
    )
  }
}